import { CallToBook, SEO, TwoColumn } from 'components';
import { Link, graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import Image from 'gatsby-image';
import React from 'react';
/** @jsx jsx */
import { Box, Button, Divider, Flex, Grid, Heading, Text, jsx } from 'theme-ui';
import slugify from 'underscore.string/slugify';
import FoodIcon from '../../static/images/svg/food-and-drink.svg';

function FoodAndDrink() {
  const imagesQuery = useStaticQuery(graphql`
    query FoodAndDrinkImages {
      menus: allContentfulMenuSection(
        sort: { fields: order, order: ASC }
        filter: { season: { eq: "summer-2020" } }
      ) {
        edges {
          node {
            id
            title
          }
        }
      }

      platter: file(relativePath: { eq: "platter@2x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1680) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dinnerOne: file(relativePath: { eq: "dinner-1@2x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1680) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pubOne: file(relativePath: { eq: "pub-taps@2x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1680) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pubTwo: file(relativePath: { eq: "pub-dessert@2x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1680) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pubThree: file(relativePath: { eq: "pub-fish@2x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1680) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      barBanner: file(relativePath: { eq: "bar-banner@2x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      blackSheep: file(relativePath: { eq: "black-sheep@2x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      foodOne: file(relativePath: { eq: "fish-table@2x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      foodTwo: file(relativePath: { eq: "dessert-table@2x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      foodThree: file(relativePath: { eq: "board-outdoor@2x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const { menus } = imagesQuery;

  return (
    <>
      <SEO title="Food and Drink" />
      <Box>
        <TwoColumn
          image={imagesQuery.platter.childImageSharp.fluid}
          bg="orange.dark"
        >
          <Heading variant="heading.xl" sx={{ color: 'white ' }} mb="l">
            We take pride in the quality and variety of food and drink that we
            offer
          </Heading>
          <Text
            variant="stnd.xxs"
            py="xs"
            sx={{
              color: 'white.100',
              borderTop: 'solid 1px',
              borderBottom: 'solid 1px',
              borderColor: 'white.20',
            }}
          >
            Food served 12pm — 9pm everyday - including Sunday
          </Text>
          <Flex
            sx={{
              justifyContent: 'space-between',
              py: 's',
              mb: 's',
            }}
          >
            <CallToBook invert variant="stnd.xs">
              (for groups of 6 or more)
            </CallToBook>
            {/* <Button variant="secondary">View our menus</Button> */}
          </Flex>
        </TwoColumn>
        <Box
          variant="boxes.container"
          pt={['xl', null, 'xxxl']}
          pb={['xxl', null, 'xxxxxl']}
        >
          <Box mb={['l', null, 'xl']} px={['m', null, 0]}>
            <Heading variant="heading.xl" mb="m" sx={{ textAlign: 'center' }}>
              We do homemade
            </Heading>
            <Text variant="subhead" sx={{ textAlign: 'center' }}>
              and we are proud of it
            </Text>
          </Box>
          <Grid
            columns={[null, null, null, '1fr 1fr']}
            gap={0}
            sx={{ boxShadow: 'l' }}
            mx={['m', null, null, 0]}
          >
            <BackgroundImage
              fluid={imagesQuery.dinnerOne.childImageSharp.fluid}
              backgroundColor={'#040e18'}
              sx={{ pb: ['56%', null, null, 0] }}
            />
            <Box
              px={['m', null, null, 'xxl']}
              pt={['l', 'xl', null, 'xl']}
              pb={['l', 'xl', null, 'xxxl']}
            >
              <Box>
                <Heading variant="heading.xl" sx={{ color: 'orange.dark' }}>
                  There is a big difference between home-cooked and homemade
                </Heading>
                <Divider />
                <Text>
                  For example, if you buy one of our pies, the filling is
                  prepared from scratch, using local ingredients wherever
                  possible, and the pastry is made, rolled and lovingly placed
                  on top by our in-house chef. Some pubs prefer the speed and
                  convenience of pre-prepared ‘ready meals’, but it wouldn’t be
                  The Station Inn, and that’s why we don’t do that.
                </Text>
              </Box>
            </Box>
          </Grid>
        </Box>
        <Grid columns={[null, '1fr 1fr 2fr']} gap={0}>
          <Image
            fluid={imagesQuery.pubOne.childImageSharp.fluid}
            style={{ display: 'block' }}
          />
          <Image
            fluid={imagesQuery.pubTwo.childImageSharp.fluid}
            style={{ display: 'block' }}
          />
          <Image
            fluid={imagesQuery.pubThree.childImageSharp.fluid}
            style={{ display: 'block' }}
          />
        </Grid>
        <Box bg="green.dark" pt={['xl', null, 'xxl']} pb={['xl', null, 'xxxl']}>
          <Grid
            variant="boxes.container"
            mx="auto"
            mb={['l']}
            sx={{ justifyContent: 'center' }}
          >
            <FoodIcon style={{ margin: 'auto' }} />
            <Heading
              variant="heading.xl"
              pt="s"
              sx={{ color: 'white.100', mb: 'xs' }}
            >
              Our menus
            </Heading>
          </Grid>

          <Box
            as="p"
            sx={{
              maxWidth: 'measure',
              mx: 'auto',
              color: 'white.100',
              mb: ['l', null, 'xl'],
              textAlign: 'center',
            }}
          >
            If you know that you want to eat at the Station Inn - let us know
            too! Whilst we naturally love being busy, we hate disappointing
            customers so we encourage everyone to book tables in advance
            wherever possible.
          </Box>

          <Flex
            mx="auto"
            sx={{ justifyContent: 'center', mb: ['xl', null, 'xxl'] }}
          >
            <CallToBook invert variant="stnd.xs">
              (for groups of 6 or more)
            </CallToBook>
          </Flex>

          <Grid
            variant="boxes.container"
            mx="auto"
            columns={[null, 'repeat(2,1fr)', 'repeat(3,1fr)']}
            gap={['m', null, 'l', 'xxl']}
            mb={['l', 'xl', 'xxxl']}
            px={['m', null, 'xl', 0]}
            sx={{ justifyContent: [null, 'center'] }}
          >
            {menus.edges.map((type) => (
              <Box
                key={type.node.id}
                p={['m', null, 'l']}
                sx={{
                  bg: 'green.light',
                  color: 'white.100',
                  boxShadow: 's',
                  textDecoration: 'none',
                  transition: 'transform .1s ease',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: 's',
                  },
                  ...type.sx,
                }}
                as={Link}
                to={`/food-menu#${slugify(type.node.title)}`}
              >
                <Text
                  sx={{
                    fontWeight: 'semibold',
                    textAlign: 'center',
                    fontSize: ['xs', null, 's'],
                    lineHeight: ['xs', null, 's'],
                  }}
                >
                  {type.node.title}
                </Text>
              </Box>
            ))}
          </Grid>
          <Box variant="boxes.container" mx="auto">
            <Text
              variant="subhead"
              sx={{ textAlign: 'center', color: 'white.100' }}
            >
              Range of specials available everyday
            </Text>
          </Box>
        </Box>
        <Box>
          <Image
            fluid={imagesQuery.barBanner.childImageSharp.fluid}
            style={{ display: 'block' }}
          />
        </Box>

        <Box px="m">
          <Grid
            variant="boxes.container"
            mx="auto"
            gap={['l', null, 'xxl']}
            columns={[null, null, '1fr 1fr']}
            pt={['l', 'xl', 'xxxxl']}
            pb={['xl', 'xxl', 180]}
            sx={{ alignItems: 'center' }}
          >
            <Box>
              <Heading variant="heading.xxl">Drink</Heading>
              <Divider />
              <Text as="p" mb="m">
                The Station Inn is so many things to so many people and we try
                and reflect this in the choice of ‘drinks’ and ‘beverages’ we
                have available.
              </Text>
              <Text as="p" mb="m">
                It has been known to occasionally be a bit chilly here in the
                Yorkshire Dales…so we have a good range of tea, coffees and hot
                chocolate. You may also have noticed that we are big on beer and
                are absolutely committed to bringing customers the very best of
                locally brewed ales – we are a genuine Yorkshire free house!
              </Text>
              <Text as="p" mb="m">
                The Station Inn has a growing range of soft drinks available
                with varieties and sizes suited to children as well as adults.
              </Text>
              <Text as="p" mb="l">
                We’re well stocked with spirits either with a mixer or on the
                rocks. An early evening gin and tonic served over ice and sipped
                in the beer garden under a retiring summer sun is the landlord’s
                favourite!
              </Text>
              <Button as={Link} to="/wine-menu">
                View our wine list
              </Button>
            </Box>
            <Box>
              <Image
                fluid={imagesQuery.blackSheep.childImageSharp.fluid}
                style={{ display: 'block' }}
                sx={{ boxShadow: 's', borderRadius: 4 }}
              />
            </Box>
          </Grid>
        </Box>

        <Grid columns={[null, '1fr 1fr 2fr']} gap={0}>
          <Image
            fluid={imagesQuery.foodOne.childImageSharp.fluid}
            style={{ display: 'block' }}
          />
          <Image
            fluid={imagesQuery.foodTwo.childImageSharp.fluid}
            style={{ display: 'block' }}
          />
          <Image
            fluid={imagesQuery.foodThree.childImageSharp.fluid}
            style={{ display: 'block' }}
          />
        </Grid>

        <Box
          sx={{ maxWidth: 'measure' }}
          mx="auto"
          py={['xl', 'xxl', 'xxxxxl']}
          px={['m', null, 0]}
        >
          <Text as="p" mb="m">
            Do talk to us if you have any special dietary requirements or
            allergies – we try and cater for everyone but are always keen to add
            to the variety of dishes we offer. Similarly, if you if you have a
            ticket for train which is due to depart shortly do let us know.
            We’ll point you towards the dishes which take less time to prepare
            and cook – it’s our version of fast food.
          </Text>

          <Text as="p" mb="m">
            We would always encourage larger groups to book, we are delighted to
            cook for large numbers but do let us know so that we can do our very
            best for you.
          </Text>

          <Text as="p" mb="xl">
            Our dishes are hand-prepared so there may be some delays at busier
            times. We promise we are working as hard as we can so that you can
            unwind and fully recharge those batteries!
          </Text>

          <CallToBook>(for groups of 6 or more)</CallToBook>
        </Box>
      </Box>
    </>
  );
}

export default FoodAndDrink;
